<template>
  <v-container>
    <v-row>
      <v-col>
        <SearchBox v-model="search" @plusClicked="newLesson" />
      </v-col></v-row>
    <v-row>
      <v-col class="pl-0 pr-0">

        <v-data-table :headers="[

          { text: 'Nom', value: 'name' },
          { text: 'Fréquence', value: 'frequency' },
          { text: 'Horaires', value: 'times', sortable: false, },
          { text: 'Actions', value: 'actions', sortable: false, },
        ]" :items="lessons" :search="search" :loading="loading" :hide-default-footer="true" disable-pagination
          :sort-by="sortBy" :sort-desc="sortDesc">

          <template v-slot:item.name="{ item }">
            <v-avatar size="25" :color="item.color">
              <v-icon small color="white" v-if="item.frequency">mdi-rotate-right</v-icon>
              <v-icon small color="white" v-else>mdi-calendar</v-icon>
            </v-avatar>
            <span class="ml-1">
              {{ item.name }}
            </span>
            <v-chip v-if="item.active == 0" color="red" small text-color="white" class="ml-2">{{
              $t("inactive")
            }}</v-chip>
          </template>

          <template v-slot:item.frequency="{ item }">
            <v-icon color="primary" v-if="item.frequency">mdi-rotate-right</v-icon>
            <!-- {{ $helpers.string.capitalize($helpers.date.getWeekdayName(item.weekday)) }} -->
            {{ getWeekdayNames(item) }}
          </template>

          <template v-slot:item.times="{ item }">
            {{ $helpers.date.getTimeStringFromTimeString(item.timeBegin) }}
            <v-icon small>mdi-chevron-right</v-icon>
            {{ $helpers.date.getTimeStringFromTimeString(item.timeEnd) }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="editLesson(item)" v-bind="attrs" v-on="on" icon>
                  <v-icon>
                    mdi-pencil-box
                  </v-icon>
                </v-btn>
              </template>
              <span>Modifier le cours</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="item.active == 1"
                  @click="item.frequency ? generatePlanningClicked(item) : pickPlanningClicked(item)" v-bind="attrs"
                  v-on="on" icon>
                  <v-icon>
                    mdi-calendar
                  </v-icon>
                </v-btn>
              </template>
              <span>Remplir mon planning</span>
            </v-tooltip>

          </template>
          <template v-slot:no-data>
            <NoTableData @click="newLesson" />
          </template>
          <template v-slot:loading>
            <Loader />
          </template>
        </v-data-table>

      </v-col>
    </v-row>
    <LessonEditionPopup ref="lessonEdition" @onSaveClicked="saveClicked" @onCloseClicked="retrieveAll" />

    <GeneratePlanningPopup ref="generatePlanningPopup" @onSaveClicked="savePlanningGenerationClicked" />
    <PickPlanningPopup ref="pickPlanningPopup" @onSaveClicked="savePlanningPickClicked" />
  </v-container>
</template>

<script>
// import LessonCard from "@/components/Admin/Lesson/LessonCard";
import LessonEditionPopup from "@/components/Admin/Lesson/LessonEditionPopup";
import GeneratePlanningPopup from "@/components/Admin/Planning/GeneratePlanningPopup";
import PickPlanningPopup from "@/components/Admin/Planning/PickPlanningPopup";
import SearchBox from "@/components/Admin/SearchBox";
import Loader from "@/components/Admin/Loader";
import NoTableData from "@/components/Admin/NoTableData.vue";

export default {
  components: {
    // LessonCard,
    LessonEditionPopup,
    GeneratePlanningPopup,
    PickPlanningPopup,
    SearchBox,
    Loader,
    NoTableData,
  },
  data() {
    return {
      loading: false,
      search: "",
      filter: {},
      lessons: [],
      sortDesc: false,
      sortBy: "name",
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.lessons.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== `Name`);
    },
  },
  methods: {
    getWeekdayNames(lesson) {
      const names = [];

      if (lesson.monday) names.push("Lundi");
      if (lesson.tuesday) names.push("Mardi");
      if (lesson.wednesday) names.push("Mercredi");
      if (lesson.thursday) names.push("Jeudi");
      if (lesson.friday) names.push("Vendredi");
      if (lesson.saturday) names.push("Samedi");
      if (lesson.sunday) names.push("Dimanche");

      return names.join(", ");
    },
    async newLesson() {
      const user = await this.$store
        .dispatch("adminauth/fetchUser")
        .catch(() => { });

      this.$refs.lessonEdition.open({ userId: user.id, active: true });
    },
    async editLesson(lesson) {
      const l = await this.$services.lesson.get(lesson.id);

      this.$refs.lessonEdition.open(l.data);
    },
    generatePlanningClicked(lesson) {
      const toDate = this.$moment(new Date());
      toDate.add({ months: 1 });

      


      this.$refs.generatePlanningPopup.open({
        lessonId: lesson.id,
        lesson: lesson,
        userId: lesson.userId,
        color: lesson.color,
        dateBegin: new Date().toISOString().substring(0, 10),
        dateEnd: toDate.toDate().toISOString().substring(0, 10),
        hourBegin: lesson.timeBegin,
        hourEnd: lesson.timeEnd,
        // days: lesson.weekday ? [lesson.weekday.toString()] : [],
      });
    },
    pickPlanningClicked(lesson) {
      const toDate = this.$moment(new Date());
      toDate.add({ months: 1 });

      this.$refs.pickPlanningPopup.open({
        lessonId: lesson.id,
        userId: lesson.userId,
        color: lesson.color,
        dateBegin: new Date().toISOString().substring(0, 10),
        dateEnd: toDate.toDate().toISOString().substring(0, 10),
        hourBegin: lesson.timeBegin,
        hourEnd: lesson.timeEnd,
        dates: [],
      });
    },
    savePlanningGenerationClicked() {
      this.retrieveAll();
    },
    savePlanningPickClicked() {
      this.retrieveAll();
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    saveClicked() {
      this.retrieveAll();
    },
    retrieveLessons() {
      this.loading = true;
      this.$services.lesson
        .getAll()
        .then((response) => {
          this.lessons = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    retrieveAll() {
      this.retrieveLessons();
    },
  },
  mounted() {
    this.retrieveAll();
  },
};
</script>