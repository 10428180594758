import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,[_c('SearchBox',{on:{"plusClicked":_vm.newLesson},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"pl-0 pr-0"},[_c(VDataTable,{attrs:{"headers":[

        { text: 'Nom', value: 'name' },
        { text: 'Fréquence', value: 'frequency' },
        { text: 'Horaires', value: 'times', sortable: false, },
        { text: 'Actions', value: 'actions', sortable: false, } ],"items":_vm.lessons,"search":_vm.search,"loading":_vm.loading,"hide-default-footer":true,"disable-pagination":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c(VAvatar,{attrs:{"size":"25","color":item.color}},[(item.frequency)?_c(VIcon,{attrs:{"small":"","color":"white"}},[_vm._v("mdi-rotate-right")]):_c(VIcon,{attrs:{"small":"","color":"white"}},[_vm._v("mdi-calendar")])],1),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.active == 0)?_c(VChip,{staticClass:"ml-2",attrs:{"color":"red","small":"","text-color":"white"}},[_vm._v(_vm._s(_vm.$t("inactive")))]):_vm._e()]}},{key:"item.frequency",fn:function(ref){
      var item = ref.item;
return [(item.frequency)?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-rotate-right")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getWeekdayNames(item))+" ")]}},{key:"item.times",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$helpers.date.getTimeStringFromTimeString(item.timeBegin))+" "),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(_vm.$helpers.date.getTimeStringFromTimeString(item.timeEnd))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editLesson(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-pencil-box ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier le cours")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(item.active == 1)?_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){item.frequency ? _vm.generatePlanningClicked(item) : _vm.pickPlanningClicked(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-calendar ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Remplir mon planning")])])]}},{key:"no-data",fn:function(){return [_c('NoTableData',{on:{"click":_vm.newLesson}})]},proxy:true},{key:"loading",fn:function(){return [_c('Loader')]},proxy:true}])})],1)],1),_c('LessonEditionPopup',{ref:"lessonEdition",on:{"onSaveClicked":_vm.saveClicked,"onCloseClicked":_vm.retrieveAll}}),_c('GeneratePlanningPopup',{ref:"generatePlanningPopup",on:{"onSaveClicked":_vm.savePlanningGenerationClicked}}),_c('PickPlanningPopup',{ref:"pickPlanningPopup",on:{"onSaveClicked":_vm.savePlanningPickClicked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }